<!-- 产品详情页子路由过渡显示页面 -->
<template>
  <div id="subView">
    <router-view></router-view>
  </div>
</template>
    
<script>
export default {
  name:'',
  props: {
     
  },
  components: {
     
  },
  data () {
    return {
    
    }
  },
  created () {
     
  },
  mounted () {
     
  },
  watch: {
     
  },
  methods: {
     
  }
}
</script>
    
<style lang="scss">
#subView {

} 
</style>